<template>
    <!-- Standard modal content -->
    <div
      :id="modalId"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="modalLabel">
              <slot name="header"> Delete Modal </slot>
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <slot name="body">
              <p>
                Are you sure you want to delete this? This action cannot be undone.
              </p>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button
                type="button"
                class="btn w-sm btn-secondary me-4"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
              >
                Delete
              </button>
            </slot>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </template>
  
  <script>
  export default {
    props: ["modalId"],
  };
  </script>
  
  <style scoped>
  .modal-content {
    width: 600px !important;
  }
  </style>